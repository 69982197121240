import { styled } from '@mui/system'
import { Box } from '@mui/material'

const ImagesContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(1050)]: {
    position: 'relative',
  },

  [theme.breakpoints.down(550)]: {
    width: '550px',
    alignSelf: 'center',
  },
}))

export default ImagesContainer
