import * as React from 'react'

import HeroContainer from './hero-container'
import HeroContent from './hero-content'
import HeroImage from './hero-image'
import ImagesContainer from './images-container'
import Wording from './wording'

const Hero = () => (
  <HeroContainer>
    <HeroContent>
      <Wording />
      <ImagesContainer>
        {/* <Profile />
        <MessageList />
        <ReplyMessage />
        <SentMessage /> */}
        <HeroImage />
      </ImagesContainer>
    </HeroContent>
  </HeroContainer>
)

export default Hero
