import { styled } from '@mui/system'

import Content from 'components/content'

const HeroContent = styled(Content)(({ theme }) => ({
  height: '100%',
  position: 'relative',

  [theme.breakpoints.down(1050)]: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default HeroContent
