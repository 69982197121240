import { Box, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import PropTypes from 'prop-types'
import * as React from 'react'

import Content from 'components/content'
import Header from './header'
import Create from './images/create.svg'
import Paid from './images/paid.svg'
import Share from './images/share.svg'

const ImageStack = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
})
const ImageAndTextBox = styled(Box)({
  flex: '0 0 250px',
  textAlign: 'center',
  padding: '64px 32px 0',
})
const ImageAndText = ({ imgSrc, imgAlt, text, height, width }) => (
  <ImageAndTextBox>
    <img src={imgSrc} alt={imgAlt} height={height} width={width} />
    <Typography
      pt={4}
      fontWeight={600}
      fontSize="1.125rem"
      maxWidth={250}
      margin="0 auto"
    >
      {text}
    </Typography>
  </ImageAndTextBox>
)
ImageAndText.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

const HowSwayWorks = () => (
  <Content id="how-sway-works-section">
    <Header />
    <ImageStack>
      <ImageAndText
        imgSrc={Share}
        width={238}
        height={191}
        imgAlt="connect image"
        text={`Connect with businesses in your area`}
      />
      <ImageAndText
        imgSrc={Paid}
        width={189}
        height={187}
        imgAlt="earn image"
        text="Earn SwayCash by responding to their messages on SwayDM"
      />
      <ImageAndText
        imgSrc={Create}
        width={201}
        height={193}
        imgAlt="create image"
        text="Redeem your SwayCash for discounts in-store!"
      />
    </ImageStack>
  </Content>
)

export default HowSwayWorks
