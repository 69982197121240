import { Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import * as React from 'react'

const StyledStack = styled(Stack)(({ theme }) => ({
  zIndex: 1,
  margin: '0 auto',
  maxWidth: '490px',
  position: 'absolute',

  [theme.breakpoints.down(1050)]: {
    position: 'initial',
    margin: 'initial',
    maxWidth: '1050px',
  },
  [theme.breakpoints.down(800)]: {
    marginBottom: 0,
  },
}))

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 900,
  color: 'white',
  fontSize: '6rem',

  [theme.breakpoints.down(1350)]: {
    fontSize: '4.75rem',
    maxWidth: '390px',
  },

  [theme.breakpoints.down(1050)]: {
    fontSize: '3.5rem',
    maxWidth: 'initial',
    textAlign: 'center',
  },

  [theme.breakpoints.down(451)]: {
    fontSize: '2.4rem',
  },
}))

const SubText = styled(Typography)(({ theme }) => ({
  fontSize: '1.375rem',
  fontWeight: 600,
  color: 'white',
  maxWidth: '310px',

  [theme.breakpoints.down(1050)]: {
    maxWidth: 'initial',
    textAlign: 'center',
  },
}))

const RectangleUnderlineContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '-4rem',
  left: '0px',
  width: '100%',

  [theme.breakpoints.down(1350)]: {
    bottom: '-2.5rem',
  },
}))

const RectangleUnderline = () => (
  <svg viewBox="0 0 238 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.579346 7.50415L237.476 0.509698L237.422 12.1622C237.422 12.1622 173.995 3.35153 108.329 10.3725C42.6625 17.3935 8.40801 31.4672 8.40801 31.4672L0.579346 7.50415Z"
      fill="white"
      fillOpacity="0.2"
    />
  </svg>
)

const Wording = () => (
  <StyledStack spacing={3}>
    <HeaderTypography variant="h1">
      Earn & Redeem{' '}
      <span style={{ position: 'relative' }}>
        Anywhere
        <RectangleUnderlineContainer>
          <RectangleUnderline />
        </RectangleUnderlineContainer>
      </span>
    </HeaderTypography>
    <SubText>Earn rewards without spending a single dollar.</SubText>
  </StyledStack>
)

export default Wording
