import * as React from 'react'
import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  textAlign: 'center',
  fontSize: '5.375rem',

  [theme.breakpoints.down(1024)]: {
    fontSize: '4.375rem',
    lineHeight: 1,
  },

  [theme.breakpoints.down(365)]: {
    fontSize: '3.5rem',
  },
}))

const Header = () => (
  <StyledTypography component="div">
    How{' '}
    <Typography
      component="span"
      color="primary"
      fontSize="inherit"
      fontWeight={800}
    >
      SwayDM
    </Typography>{' '}
    Works
  </StyledTypography>
)

export default Header
