import { Box } from '@mui/material'
import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

const Container = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '100px',
  right: '100px',

  [theme.breakpoints.down('lg')]: {
    right: '24px',
  },

  [theme.breakpoints.down(800)]: {
    '&&&': {
      right: 'initial',
      // left: '50%',
      left: '-30px',
    },
  },
}))

const DesktopImage = styled('div')(({ theme }) => ({
  [theme.breakpoints.down(800)]: {
    display: 'none',
  },
}))

const MobileImage = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(800)]: {
    display: 'none',
  },
}))

const HeroImage = () => (
  <Container
    sx={{
      position: 'absolute',
      top: 20,
      right: {
        xs: 24,
        lg: 200,
      },
    }}
  >
    <DesktopImage>
      <StaticImage
        src="images/desktop-hero.png"
        alt="profile"
        placeholder="none"
        loading="eager"
        width={800}
      />
    </DesktopImage>
    <MobileImage>
      <StaticImage
        src="images/mobile-hero.png"
        alt="profile"
        placeholder="none"
        loading="eager"
        width={800}
      />
    </MobileImage>
  </Container>
)

export default HeroImage
