import { Box } from '@mui/material'
import { styled } from '@mui/system'

import { purpleBackground } from 'shared/styles'

const HeroContainer = styled(Box)(({ theme }) => ({
  height: '850px',
  background: purpleBackground,

  [theme.breakpoints.down(1050)]: {
    height: '1000px',
    overflow: 'hidden',
  },

  [theme.breakpoints.down(800)]: {
    height: '1250px',
  },
}))

export default HeroContainer
