import React from 'react'

import Footer from 'components/footer'
import Layout from 'components/layout'
import NavBar from 'components/navbar'
import { PropTypes } from 'prop-types'
import Hero from 'views/index/hero'
import HowSwayWorks from 'views/index/how-sway-works'

const contentId = 'main'

const Index = ({ location }) => {
  return (
    <Layout location={location} contentId={contentId}>
      <NavBar />
      <main id={contentId}>
        <Hero />
      </main>
      <div id="how-sway-works">
        <HowSwayWorks />
      </div>
      <Footer />
    </Layout>
  )
}

Index.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Index
